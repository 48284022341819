import React, { useState } from 'react';
import { useAuth } from '../contexts/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import axios from "axios";
import { Alert, Button } from "reactstrap";
import LoaderContainer from "../components/ui/LoaderContainer/LoaderContainer";
import { UpdatePassword } from '../data/administration';

const ChangePassword = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const changePass = (e) => {
        var fo = e.target;
        let err = false;
        setError(null);
        e.preventDefault();
        if (fo.oldpwd.value == "" || fo.newpdw.value == "") {
            err = true;
            setError("Password can't be empty");
        }else
        if (fo.confpdw.value != fo.newpdw.value)
        {
            err = true;
            setError("Passwords do not match");
        }else
        if (vl(fo.newpdw.value) == 0) {
            err = true;
            setError("Password must have at least 6 characters");
        }else
        if (vl(fo.newpdw.value) == -1) {
            err = true;
            setError("Password must have at least one special character");
        }else
        if (!err) {
            UpdatePassword({UserId:auth.user.Id,oldPassword:fo.oldpwd.value,NewPassword:fo.confpdw.value}).then(rez => {

            }).catch(ex=>{
                setError("Password did not match current password");
            })
            auth.token = null;
            auth.user = null;
            auth.userRole = null;
            navigate("/");
        }
    }
    const vl = (pw) => {
        var newPassword = pw;
        var minNumberofChars = 6;
        var maxNumberofChars = 16;
        var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*_^])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        if (newPassword.length < minNumberofChars || newPassword.length > maxNumberofChars) {
            return 0;
        }
        if (!regularExpression.test(newPassword)) {
            return -1;
        }
        return true;
    }
    const url = "/auth/InitiateSingleSignOn?returnUrl=" + queryParams.get('returnUrl');
    return (
        <div className="loginGB">
            {error != null ?
                <Alert color="danger">{error}</Alert>
                : null}
            <div className="login-wrapper">


                <div className="login-form">

                    <img src="/images/dcslogo.png" alt="Dcs Logo" />
                    {
                        !loading ? <>
                            <h2>Change password</h2>
                            <form className="form mh-400" method="post" onSubmit={changePass}>
                                <div className="form-group">
                                    <label htmlFor="username">Current Password</label>
                                    <input type="password" required name="oldpwd" placeholder="old password" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username">New Password</label>
                                    <input type="password" required name="newpdw" placeholder="new password" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username">Confirm Password</label>
                                    <input type="password" required name="confpdw" placeholder="confirm password" />
                                </div>
                                <div className="form-group">
                                    <button className="button" type="submit">Update</button>
                                </div>
                            </form>
                            <form className="form mh-400" method="get" action={url} >
                                <input type="hidden" value={queryParams.get('returnUrl')} name="returnUrl" />
                            </form>
                        </>
                            : <LoaderContainer />}
                </div>

            </div>
        </div>
    )
}
export default ChangePassword;