import React, { useState, forwardRef, useRef } from 'react';
import "./Toogle.css";
import { Col, Label, Row } from 'reactstrap';
const DcsToggle = forwardRef((props, ref) => {
  const { name, defaultChecked, disabled } = props;
  const [isToggled, toggle] = useState(defaultChecked);

  const localRef = useRef();
  const callback = () => {
    toggle(!isToggled);
    console.log(ref);
  }

  return (<>
    <div className='form-group'>
      <Label>
        <div dangerouslySetInnerHTML={{__html:props.data.label}}/>
      </Label>
      <Label className="toggle ">
        <input type="checkbox" ref={ref == undefined ? localRef : ref} name={name} disabled={disabled} defaultChecked={isToggled} onClick={callback} />
        <span />
      </Label>
    </div>
  </>
  )
});
export default DcsToggle;