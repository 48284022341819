import { createContext, useContext } from "react";
export const FilterContext = createContext();
export const FilterRenderer = (props) => {
    const {tabIndex, column, children}=props;
    const filters = useContext(FilterContext);
    console.log(filters.enabled);
    return (
        <>
            <div>{column.name}</div>
            {filters.enabled && <div>{children({ tabIndex, filters })}</div>}
        </>
    );
}