import React, { useEffect, forwardRef,useRef } from 'react';
import "./Modal.css"
import { Button, Row, Col } from 'reactstrap';

/** 
* Title: Modal
* Functionality: Creates a popup of with content
* @param callBack: Function that is passed from parent component to set set the show variable to false;
* @param open: variable that opens the Modal
* @param title: variable that contains the h3 thag name of the popup
**/
const Modal = forwardRef((props,ref) => {   
   
    //Change open state based on props.open
    useEffect(() => {      
        ref.current.showModal();
    }, [])


    //Set open state to false and call callback function
    const CloseModal = () => {
        props.callBack();
        ref.current.close();
    }
    const handleKeyDown = (e) => {        
        if (e.key == 'Escape'){
            props.callBack();                      
        }       
      }
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
      }, []);
    return (
        <>
            <dialog className="dcs-modal" ref={ref}>
                <Button className="dcs-modal-close" onClick={CloseModal}>X</Button>
                <Row>
                    <Col>
                        <h3>{props.title}</h3>
                    </Col>
                </Row>
                {props.children}

            </dialog>
        </>
    )
})

export default Modal;