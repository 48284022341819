import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Button, ButtonGroup, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Card from '../Card/Card';


import LoaderContainer from '../LoaderContainer/LoaderContainer';
import { useAuth } from '../../../contexts/authContext';

import moment from 'moment';
import LineChart from '../Charts/Line/Line';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Defs } from '@react-pdf/renderer';
import ReactPDFChart from 'react-pdf-charts';
import logo from './dcslogo.png';
import {
    Area,
    AreaChart,
    Bar,
    CartesianGrid,
    ComposedChart,
    ResponsiveContainer,
    BarChart,
    Legend,
    Line,
    XAxis,
    YAxis,
    Cell,
} from 'recharts';
import SupplierCounter from '../SupplierCounter/SupplierCounter';
import CustomerRatingScore from '../CustomerRatingScore/CustomerRatingScore';
import CustomerRatingNScore from '../CustomerRatingNScore/CustomerRatingNScore';

// Create styles
const styles = StyleSheet.create({
    page: { fontSize: 11, paddingTop: 24, paddingLeft: 10, paddingRight: 20, lineHeight: 1.5, flexDirection: 'column' },
    spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E" },
    titleContainer: { flexDirection: 'row', marginTop: 24, flexWrap: "wrap" },
    logo: { width: 90 },
    reportTitle: { fontSize: 16, textAlign: 'center' },
    reportView: { display: "flex", flexDirection: 'row', flexWrap: "wrap" },
    theader: { marginTop: 20, fontSize: 10, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },
    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },
    tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },
    total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: 'whitesmoke', borderBottomWidth: 1 },
    tbody2: { flex: 2, borderRightWidth: 1, }
});
const pClasses = [
    { id: 0, pill: "pGray", rating: "N/A" },
    { id: 1, pill: "plGreen", rating: "Great" },
    { id: 2, pill: "pdGreener", rating: "Good" },
    { id: 3, pill: "pOrange", rating: "Bad" },
    { id: 4, pill: "pRed", rating: "Critical" }
];

const ReportPDF = (props) => {

    const { suppliers, countries } = props;
    const [target, setTarget] = useState(undefined);
    const [ratingSource, setRatingSource] = useState([]);
    const [supplierCyberPosture, setSupplierCyberPosture] = useState(null);
    const [content, setContent] = useState(null);
    const [geoData, setGeoData] = useState([]);
    const [filters, setFilters] = useState({});
    const [assets, setAssets] = useState([]);
    const [postureSource, setPostureSource] = useState(null);
    const [generalRating, setGeneralRating] = useState(null);
    const [formula, setFormula] = useState(null);
    const [avgScore, setAvgScore] = useState(null);
    const auth = useAuth();


    useEffect(() => {
        if (suppliers != null) {
            let result = [];
            let frating = [];
            let scposture = [];
            let SAssets = [];
            let FAssets = [];
            let SupplierTable = []
            suppliers.Rating.forEach((x, index, arr) => {
                let posture = 0;
                x.SupplierRatings.forEach((z, indexx, arrr) => {
                    posture += z.PostureRating;
                    if (SAssets.filter(p => p.Supplier.Name == z.Supplier.Name).length == 0) {
                        scposture.push({
                            name: z.Supplier.Name,
                            label: z.QuestionaryRating != -1 ? pClasses[z.Rating.Rating].rating : pClasses[0].rating,
                            value: Math.round(z.PostureRating).toFixed(0)
                        });
                        SupplierTable.push(z);
                        SAssets.push(z);
                    }
                });

                //SAssets = [...new Set(SAssets)];
                //console.log(SAssets);
                posture = Math.round(posture / x.SupplierRatings.length).toFixed(0);
                if (posture < 0) {
                    posture = 0;
                }
                frating.push({
                    name: moment().add((index * -1), "M").endOf('month').format("YYYY-MM-DD"),
                    label: pClasses[x.Rating].rating,
                    value: x.Score
                })
                result.push({
                    name: moment().add((index * -1), "M").endOf('month').format("YYYY-MM-DD"),
                    label: pClasses[x.Rating].rating,
                    value: posture
                })
            });

            FAssets = SAssets.map(x => { const Sname = x.Supplier.Name; return x.AssetsGeo.map(z => { return { Supplier: Sname, Name: z.Name, CountryId: z.CountryId, DateCreated: z.DateCreated } }) }).flat();
            setAssets(FAssets);
            setSupplierCyberPosture(scposture);
            console.log(frating.reverse());
            setRatingSource(frating.reverse());
            setPostureSource(result.reverse());
            setGeneralRating(suppliers.Rating.Rating);
            setAvgScore(suppliers.Rating.Score);
            setContent(SupplierTable);
        }
    }, [])

    const ReportHeader = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.reportTitle}>Third Watch Report</Text>
            </View>
        </View>
    );
    const ReportGeneralRating = () => {
        return (<>
            <View style={styles.titleContainer}>
                <View style={styles.spaceBetween}>
                    <Text style={{ marginLeft: "24" }}> General Risk Rating</Text>
                </View>
            </View>
            <ReactPDFChart style={{ width: '100%', marginTop: "0", flexDirection: 'row' }}>
                <AreaChart
                    width={800}
                    height={200}
                    data={ratingSource.reverse()}
                    style={{ width: '100%', flexDirection: 'row', marginTop: "0" }}
                >
                    <Defs>
                        <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#1D56A3" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#1D56A3" stopOpacity={0} />
                        </linearGradient>
                    </Defs>
                    <XAxis dataKey="name" stroke="gray" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#1D56A3"
                        fillOpacity={1}
                        fill="url(#total)"
                        isAnimationActive={false}
                        label={{ position: 'top' }}
                    >
                        {ratingSource.map((entry, index) => (
                            <Cell key={`cell-${index}`} />
                        ))}
                    </Area>
                </AreaChart>
            </ReactPDFChart>
        </>);
    }
    const ReportCyberPostureRating = () => {
        return (<>
            <View style={{ marginTop: "0px", marginLeft: "24px" }}>
                <Text s>Cyber posture Rating</Text>
            </View>
            <ReactPDFChart style={styles.reportView}>
                <AreaChart
                    width={800}
                    height={200}
                    data={ratingSource}
                    style={{ margin: "0", padding: "0" }}
                >
                    <Defs>
                        <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#1D56A3" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#1D56A3" stopOpacity={0} />
                        </linearGradient>
                    </Defs>
                    <XAxis dataKey="name" stroke="gray" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#1D56A3"
                        fillOpacity={1}
                        fill="url(#total)"

                        isAnimationActive={false}
                        label={{ position: 'top' }}
                    >
                        {ratingSource.map((entry, index) => (
                            <Cell key={`cell-${index}`} />
                        ))}
                    </Area>
                </AreaChart>
            </ReactPDFChart>
        </>
        );
    }

    const ReportSupplierCyberPosture = () => {
        return (<>
            <View style={{ marginTop: "0px", marginLeft: "24px" }}>
                <Text >Supplier posture Rating</Text>
            </View>
            <ReactPDFChart style={styles.reportView}>
                <BarChart
                    width={800}
                    height={200}
                    data={supplierCyberPosture}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    {/* <Defs>
                            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#1D56A3" stopOpacity={1} />
                                <stop offset="95%" stopColor="#1D56A3" stopOpacity={0} />
                            </linearGradient>
                        </Defs> */}
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Bar
                        type="monotone"
                        dataKey="value"
                        fill="#1D56A3"
                        isAnimationActive={false}
                        label={{ position: 'top' }}
                    >
                        {supplierCyberPosture.map((entry, index) => (
                            <Cell key={`cell-${index}`} />
                        ))}

                    </Bar>
                </BarChart>
            </ReactPDFChart>
        </>
        );
    }
    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', paddingLeft: "24" }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Supplier</Text>
            </View>
            <View style={styles.theader}>
                <Text>Cyber Posture Rating</Text>
            </View>
            <View style={styles.theader}>
                <Text>Questionary Rating</Text>
            </View>
            <View style={styles.theader}>
                <Text>Risk Rating</Text>
            </View>
        </View>
    );
    const TableBody = () => (
        content.map((receipt) => (
            <Fragment key={receipt.Supplier.Id}>
                <View style={{ width: '100%', flexDirection: 'row', paddingLeft: "24", paddingBottom: "20" }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >{receipt.Supplier.Name}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.PostureRating < 0 ? 0 : receipt.PostureRating} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.QuestionaryRating < 0 ? 0 : receipt.QuestionaryRating}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{(pClasses[receipt.Rating.Rating].rating)}</Text>
                    </View>
                </View>
            </Fragment>
        ))
    );
    const CardRating = () => (
        <View>
            <Card>
                <Row className="customerGeneralInfo">
                    <SupplierCounter number={suppliers.length > 0 ? suppliers.NewSuppliers : 0} percentage={100} />
                    <Col md="4" xs="12" className='supplierCounter'>
                        {generalRating != null ?
                            <CustomerRatingScore gRating={generalRating != null ? pClasses[generalRating].score : pClasses[0].score} /> : null
                        }
                    </Col>
                    {avgScore != null ?
                        <CustomerRatingNScore rating={Math.round(avgScore)} /> : null}
                </Row>
            </Card>
        </View>
    )
    return (<>
        {ratingSource != null && content != null ?
            <PDFViewer width={900} height={768} className='app'>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <ReportHeader />
                        {/* <CardRating /> */}
                        <ReportGeneralRating />
                        {content != null ? <>
                            <TableHead />
                            <TableBody /></> : null}
                        <ReportCyberPostureRating />
                        <ReportSupplierCyberPosture />

                    </Page>
                </Document>
            </PDFViewer>

            : null}
    </>

    )
}
export default ReportPDF;