import React from 'react';
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DCSBarChart = (props) => {
    const colors = ["#1D56A3", '#8884d8', '#82ca9d', '#ffc658']
    const { data, subtitle } = props;
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            //console.log(payload);
            return (
                <div className="custom-tooltip">
                    <p className="primary" style={{borderRadius:"5px",color:"#FFF",padding:"5px"}}>Posture: {`${payload[0].payload.label}`}<br/>
                    Value {`${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        data.length > 0 ?
            <div>
                <div className="title">{subtitle}</div>
                <ResponsiveContainer width="100%" aspect={2 / 1}>
                    <BarChart
                        width={730}
                        height={250}
                        data={data}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                        <defs>
                            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#1D56A3" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#1D56A3" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <YAxis />

                        <Tooltip content={CustomTooltip}  cursor={{fill: 'transparent'}} />
                        <Bar 
                            type="monotone"
                            dataKey="value"
                            fill="url(#total)"
                        />
                    </BarChart>

                </ResponsiveContainer>
            </div>
            : <h3 style={{ textAlign: "center", color: "gray" }}>No data yet</h3>
    );
}
export default DCSBarChart;

