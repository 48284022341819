import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, isRouteErrorResponse, useParams } from 'react-router-dom';
import { Col, Row, Button, OffcanvasBody, Offcanvas, OffcanvasHeader, UncontrolledTooltip, Label, ButtonGroup } from 'reactstrap';
import LoaderContainer from '../../components/ui/LoaderContainer/LoaderContainer';
import DynamicTable from '../../components/ui/Table/Table';
import Card from '../../components/ui/Card/Card';
import { GetAssets, IgnoreScan, UpdateAsset } from '../../data/Assets';
import Countries from '../../data/countries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCirclePlus, faFileExcel, faSync } from '@fortawesome/free-solid-svg-icons';
import { RefreshAssets, Suppliers } from '../../data/suppliers';
import { CellExpanderFormatter } from '../../components/ui/Table/CellExpanderFormatter';
import "./Assets.css";
import DataTable from '../../components/ui/Table/DataTable';
import BusinessImpact from '../../components/ui/BusinessImpact/BusinessImpact';
import BusinessImpactBig from '../../components/ui/BusinessImpactBig/BusinessImpactBig';
import DcsToggle from '../../components/ui/Designer/Toggle/DcsToggle';
import { useAuth } from '../../contexts/authContext';
import AssetModal from '../../components/ui/AssetModal/AssetModal';
import moment from "moment";
import ExportExcel from '../../utils/ExportFunction';


const Assets = (props) => {
    const Title = "Supplier Assets";
    const { id } = useParams();
    const [assetdata, setAssetData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initialSupplierSort = [{ columnKey: 'id', direction: 'ASC' }];
    const initialAssetSort = [{ columnKey: 'Risk_Level', direction: 'DESC' }];
    const [showDrawer, setShowDrawer] = useState(false);
    const [assetDetails, setAssetDetails] = useState({});
    const [ignoreList, setIgnoreList] = useState([]);
    const auth = useAuth();
    const ScanDetailsColumns = [
        {
            key: "Description",
            name: "Description",
            width: "20%",
        },
        {
            key: "Risk_Description",
            name: "Risk description",
            width: "20%",
        },
        {
            key: "CVE",
            name: "CVE",
            width: "20%",
        },
        {
            key: "Risk_Level",
            name: "Risk Level",
            width: "20%",
        },
        {
            key: "ScanType",
            name: "Scan Type",
            width: "20%",
        }
    ]
    const ToogleDrawer = () => {
        setShowDrawer(!showDrawer);
    }
    function onRowsChange(rows, { indexes }) {
        const row = rows[indexes[0]];

        if (row.type === 'MASTER') {
            if (row.expanded) {
                rows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    id: Math.random(),
                    parentId: row.Id
                });
            } else {
                rows.splice(indexes[0] + 1, 1);
            }
            setAssetData(rows);
        }
    }
    const columns2 = [
        {
            name: 'Name',
            header: 'Asset Name',
            sortable: true,
            defaultFlex: 1,
            headerCellClass: 'dcs-sort',
            render: (params) => {
                return (
                    <>
                        <a id={'contactLink-' + params.data.Id}>{params.data.Name}</a>
                        {/* <UncontrolledTooltip
                            placement="bottom"
                            target={'contactLink-' + params.data.Id}
                        >
                            Show Scan Details
                        </UncontrolledTooltip> */}
                    </>
                )
            }
        },
        {
            name: 'IsOfInterest',
            header: "Is of interest?",
            defaultFlex: 1,
            shouldComponentUpdate: false,
            render: ({ data }) => {
                return <DcsToggle disabled={true} data={{ lable: "" }} defaultChecked={data.IsOfInterest != undefined ? data.IsOfInterest : true} callback={() => { }} name={`IsOfInterest_${data.Id}`} />
            }
        },
        {
            header: "Network",
            name: "Network",
            defaultFlex: 1,
            sortable: true,
            headerCellClass: 'dcs-sort'
        },
        {
            header: "Country",
            name: "CountryId",
            defaultFlex: 1,
            sortable: true,
            headerCellClass: 'dcs-sort',
            sort: (p1, p2) => p2 > p1,
            render: (params) => {
                return (
                    <>
                        {countries.length > 0 && params.data.CountryId != null ? countries.find(x => x.Id == params.data.CountryId).Name : ""}
                    </>
                )
            }
        },
        {
            name: 'BusinessImpact',
            header: 'Business Impact',
            defaultFlex: 1,
            sortable: true,
            headerCellClass: 'dcs-sort',
            render: (params) => {
                return (
                    <>
                        <BusinessImpact impactLevel={params.data.BusinessImpact} />
                    </>
                )
            }
        },
        {
            header: 'Summary',
            name: 'Summary',
            defaultFlex: 1,
            sortable: true,
            headerCellClass: 'dcs-sort',
            render: (params) => {
                if (params.data.AssetScans != undefined) {
                    const l = params.data.AssetScans;
                    let Items = 0;
                    let Issues = 0;
                    const Scans = [...new Set(l.map(item => item.ScanType))].length;

                    l.map(x => {
                        Items++;
                        Issues += x.Risk_Level >= 2 ? 1 : 0;
                    })
                    if (Scans == 0) {
                        return <>Pending Scans</>
                    } else
                        return (
                            <>
                                <div>{Scans} Scans ({Items} Items) with {Issues} issues found</div>
                            </>
                        )
                }
            }
        },
        {
            name: 'Added',
            header: 'Discovery',
            defaultFlex: 1,
            sortable: true,
            headerCellClass: 'dcs-sort'
        },
        {
            key: 'Last scanned on',
            name: 'Last scanned on',
            sortable: true,
            headerCellClass: 'dcs-sort',
            render: ({ data }) => {
                //console.log(data);
                //console.log(moment(data.AssetScans.length>0?data.AssetScans[0].DateCreated:"No data").format("DD-MM-YYYY"));
                return moment(data.AssetScans.length > 0 ? data.AssetScans[0].DateCreated : "No data").format("DD-MM-YYYY");
            }
        },
    ];
    const onSelectionChange = useCallback((selected) => {
        setAssetDetails(selected.data);
        ToogleDrawer();
    }, [])
    useEffect(() => {
        setIsLoading(true);
        GetAssets(null, id).then(results => {
            //console.log(results.data);
            setAssetData(results.data);
            Countries().then(x => {
                setCountries(x.data);
                setIsLoading(false);
            });

        });
    }, [])
    const RefreshAssetsClick = () => {
        Suppliers(id).then(result => {
            setIsLoading(true);
            setAssetData(null);
            RefreshAssets(result.data.Supplier.SupplierRef, auth.user.ClientId).then(r => {
                GetAssets(null, id).then(results => {
                    //console.log(results.data);
                    setAssetData(results.data);
                    Countries().then(x => {
                        setCountries(x.data);
                        setIsLoading(false);
                    });

                });
                //window.location.reload(false);
            })
        });
    }
    const IngoreAssetScan = (scanid) => {
        if (ignoreList.indexOf(scanid) == -1 || assetDetails.AssetScans.filter(x => x.Id == scanid)[0].Ignore == false) {
            setIgnoreList([...ignoreList, scanid]);
            console.log(ignoreList);
        } else {
            const newIgnoreList = ignoreList.filter(x => x != scanid);
            setIgnoreList(newIgnoreList);
        }
        assetDetails.AssetScans.filter(x => x.Id == scanid)[0].Ignore = !assetDetails.AssetScans.filter(x => x.Id == scanid)[0].Ignore;
        GenerateScanRows(assetDetails.AssetScans);
        // setIsLoading(true);
        // IgnoreScan(scanid).then(rez => {
        //     ToogleDrawer();
        //     GetAssets(null, id).then(results => {

        //         setAssetData(results.data);
        //         setIsLoading(false);
        //         // Countries().then(x => {
        //         //     setCountries(x.data);
        //         //     setIsLoading(false);
        //         // });

        //     });
        //     console.log(rez);
        // })
    }
    const ExportAssets = () => {
        const jsonArray = [];
        assetdata.forEach((instance, indexx, record) => {
            let Items = 0;
            let Issues = 0;
            const Scans = [...new Set(record.map(item => item.ScanType))].length;

            record.map(x => {
                Items++;
                Issues += x.Risk_Level >= 2 ? 1 : 0;
            })
            var tmp = {
                "Name": record[indexx].Name,
                "IsOfInterest": record[indexx].IsOfInterest,
                "Network": record[indexx].Network,
                "Country": countries.filter(x => x.Id == record[indexx].CountryId)?.Name,
                "BusinessImpact": record[indexx].BusinessImpact,
                "Summary": `${ Scans } Scans(${ Items } Items) with ${ Issues } issues found`,
                "Discovery": record[indexx].Added,
                "LastScand": moment(record[indexx].AssetScans.length > 0 ? record[indexx].AssetScans[0].DateCreated : "No data").format("DD-MM-YYYY")
            }
            jsonArray.push(tmp);
        });

        ExportExcel(jsonArray, "Assets", "Assets.xlsx");
    }
    const SaveChanges = () => {
        setIsLoading(true);
        assetDetails.IsOfInterest = TooggleRef.current.checked ? 1 : 0;
        // console.log(TooggleRef.current.checked);
        Promise.all(ignoreList.map(x => {
            return IgnoreScan(x).then(results => {
                setAssetData(results.data);
            });
        })).then(() => {
            setIgnoreList([]);
            UpdateAsset(assetDetails).then(x => {
                GetAssets(null, id).then(results => {
                    ToogleDrawer();
                    setAssetData(results.data);
                    setIsLoading(false);

                });
            })
        })
    }
    Array.prototype.groupBy = function (hash) {
        var _hash = hash ? hash : function (o) { return o; };

        var _map = {};
        var put = function (map, key, value) {
            if (!map[_hash(key)]) {
                map[_hash(key)] = {};
                map[_hash(key)].group = [];
                map[_hash(key)].key = key;

            }
            map[_hash(key)].group.push(value);
        }

        this.map(function (obj) {
            put(_map, obj, obj);
        });

        return Object.keys(_map).map(function (key) {
            return { key: _map[key].key, group: _map[key].group };
        });
    }
    const GenerateScanRows = (rows) => {
        return rows.map(x => {
            if (x.Description.indexOf("Nothing was found") === -1 && x.Risk_Description.indexOf("No risk") === -1) {
                return (<>
                    <Row className="m-0" style={{ backgroundColor: x.Risk_Level >= 2 && x.Ignore == 0 ? "red" : "transparent", color: x.Risk_Level >= 2 && x.Ignore == 0 ? "#FFF" : "#000", padding: "10px", marginBottom: "20px", borderRadius: "10px 10px 0px 0px" }}>
                        <Col>
                            <span style={{ color: x.Risk_Level >= 2 && x.Ignore == 0 ? "#FFF" : "#000" }}>{x.Description}</span>
                            {
                                x.Risk_Level >= 2 ? <>
                                    <Button className="DefaultButton" size="sm" id={'ignoreCVE-' + x.Id} style={{ float: "right" }} onClick={() => IngoreAssetScan(x.Id)}>X</Button>
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target={'ignoreCVE-' + x.Id}
                                    >
                                        Ignore issue
                                    </UncontrolledTooltip>
                                </> : null
                            }
                        </Col>
                    </Row>
                    {
                        x.ScanType != "Port" ?
                            <Row className="m-0" style={{ backgroundColor: x.Risk_Level >= 2 && x.Ignore == 0 ? "red" : "transparent", padding: "10px", borderRadius: "0 0 10px 10px" }}>
                                <Col>
                                    <div className="form-group" style={{ height: "100px", backgroundColor: "#d3d3d3", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: x.CVE + "<br/>" + x.Risk_Description }} />
                                </Col>
                            </Row> : null
                    }

                    {
                        x.ScanType == "Port" && x.Ports != undefined ?
                            GenerateScanRowsTCP(JSON.parse(x.Ports)) : null
                    }
                </>)
            }
        })

    }
    const GenerateScanRowsTCP = (rows) => {
        return (<>
            <Row>
                <Col>
                    <table className="table" style={{ color: "#000" }}>
                        <tr>
                            <th style={{ color: "#000" }}>Port</th>
                            <th style={{ color: "#000" }}> Status</th>
                            <th style={{ color: "#000" }}>Service name</th>
                            <th style={{ color: "#000" }}>Product</th>
                        </tr>
                        {rows.map(p => {
                            return (<>
                                <tr>
                                    <td>{p.port_number}</td>
                                    <td>{p.port_state}</td>
                                    <td>{p.service_name}</td>
                                    <td>{p.service_product}</td>
                                </tr>
                            </>)
                        })}
                    </table>
                </Col>
            </Row>
        </>
        );
    }
    const TooggleRef = useRef();
    const UpdateBusinessImpact = (businessImpact) => {
        assetDetails.BusinessImpact = businessImpact;
        // const newData = supplierData;
        // newData.date_generale.businessImpact.businessImpact = businessImpact;
        // setSupplierData({ ...supplierData, newData });
    }
    const addAssetModal = () => {
        setShowModal(!showModal);
    }
    const ShowHideQuestionaryModal = () => {

    }
    return (
        <>
            {showModal ?
                <AssetModal SupplierId={id} setShowModal={ShowHideQuestionaryModal} /> : null}
            <Offcanvas backdrop={true}
                direction="end"
                scrollable
                isOpen={showDrawer}
                style={{ color: "#000" }}
                toggle={ToogleDrawer} >
                <OffcanvasHeader toggle={ToogleDrawer}>
                    <div>{assetDetails.Name}</div>
                </OffcanvasHeader>
                <OffcanvasBody className='assetDetails'>
                    <Row>
                        <Col>Ip</Col>
                        <Col>{assetDetails.Ip}</Col>
                    </Row>
                    <Row>
                        <Col>Network</Col>
                        <Col>{assetDetails.Network}</Col>
                    </Row>
                    <Row>
                        <Col>GeoLocation</Col>
                        <Col>{assetDetails.CountryId != undefined ? countries.find(x => x.Id == assetDetails.CountryId).Name : ""}</Col>
                    </Row>
                    <Row>
                        <Col>Created At</Col>
                        <Col>{assetDetails.DateCreated}</Col>
                    </Row>
                    <Row>
                        <Col>Creation Type</Col>
                        <Col>{assetDetails.Added}</Col>
                    </Row>
                    <Row>
                        <Col>Business Impact</Col>
                        <Col><BusinessImpactBig value={assetDetails.BusinessImpact} callBack={UpdateBusinessImpact} /></Col>
                    </Row>
                    <Row>
                        <Col>Is of interest?</Col>
                        <Col><DcsToggle ref={TooggleRef} data={{ lable: "" }} defaultChecked={assetDetails.IsOfInterest != undefined ? assetDetails.IsOfInterest : true} callback={() => { }} name={`IsOfInterest_${assetDetails.Id}`} /></Col>
                    </Row>
                    <hr />
                    {
                        assetDetails.AssetScans != undefined ?
                            assetDetails.AssetScans.groupBy(function (o) { return JSON.stringify({ a: o.ScanType }); }).map((x) => {
                                // console.log(x);
                                return (<>
                                    <Row>
                                        <Col>
                                            <b>Scan Type: {x.key.ScanType}</b>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #d3d3d3" }}>
                                        <Col>
                                            {GenerateScanRows(x.group)}
                                        </Col>
                                    </Row>
                                </>)
                            }) : null
                    }
                    <Row>
                        <Col>
                            <Button className='btn btn-primary' onClick={() => { SaveChanges() }}>Save Changes</Button>
                        </Col>
                    </Row>
                </OffcanvasBody>
            </Offcanvas>
            <Row className="dark">
                <Col xs md="12" className="headerText">
                    <h3 className="dcs-Title bold">{Title}</h3>
                    <ButtonGroup>
                        <Button className='btn-primary ml-auto' id="addAsset" onClick={() => { addAssetModal() }}><FontAwesomeIcon icon={faFileCirclePlus} /></Button>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={"addAsset"}
                        >
                            Add Asset
                        </UncontrolledTooltip>
                        <Button className='btn-primary' id="syncAssets" onClick={() => { RefreshAssetsClick() }}><FontAwesomeIcon icon={faSync} /></Button>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={"syncAssets"}
                        >
                            Syncronize Scans
                        </UncontrolledTooltip>
                        <Button className='btn-primary' id="exportAssets" onClick={() => { ExportAssets() }}><FontAwesomeIcon icon={faFileExcel} /></Button>
                        <UncontrolledTooltip
                            placement="bottom"
                            target={"exportAssets"}
                        >
                            Export Assets
                        </UncontrolledTooltip>
                    </ButtonGroup>
                </Col>

            </Row>
            <Row>
                <Col xs md="12">
                    <span style={{ color: "gray", margin: "0px 0px 20px 0px;", display: "flex" }} >Some scans take longer than 30 minutes. Even after initial scans have been completed, please use the refresh button to update the reports. Minimum Scans should be 2 per each Asset.</span>
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <Card>
                        {
                            !isLoading ?
                                <DynamicTable showTitle={false} rowHeight={(row) => (row.type === 'DETAIL' ? 300 : 45)} onRowsChange={onRowsChange} defaultsortColumns={initialSupplierSort} rowSelect={false} pagination={true} title="Assets" rows={assetdata} PageSize={20} columns={columns} />
                                : <LoaderContainer />
                        }
                    </Card>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <Card>
                        {
                            !isLoading ?
                                // <DynamicTable showTitle={false} rowHeight={(row) => (row.type === 'DETAIL' ? 300 : 45)} onRowsChange={onRowsChange} defaultsortColumns={initialSupplierSort} rowSelect={false} pagination={true} title="Assets" rows={assetdata} PageSize={20} columns={columns} />
                                <DataTable rowHeight={60} columns={columns2} showCellBorders="horizontal" showHeader={true} data={assetdata} virtualizeColumns={false} pagination defaultLimit={20} id="assets" enableSelection={true} onSelectionChange={onSelectionChange} />
                                : <LoaderContainer />
                        }
                    </Card>
                </Col>
            </Row>

        </>
    )
}
export default Assets;