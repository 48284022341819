import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
// import { Col, Row } from "reactstrap";
import DataGrid, { SortColumn, SelectColumn } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import "./Table.css";
import Pagination from '../Pagination/Pagination';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap";
import { FilterContext } from "./filterRender";
const DynamicTable = (props) => {
    const { onRowsChange, showTitle, title, rows, style, columns, getRowId, rowHeight, headerHeight, defaultsortColumns, pagination = false, PageSize = 6, rowSelect = false, buttons, tips,filterFields ,filteredRows } = props;
    const [sortColumns, setSortColumns] = useState(defaultsortColumns != undefined ? defaultsortColumns : []);
    const [selectedRow, setSelectedRows] = useState(() => new Set());
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState(filterFields!=undefined?filterFields:{});
    useEffect(() => {
        //console.log(rows.length);
    }, [rows])
    if (rowSelect) {
        columns.unshift(SelectColumn);
    }
    const getComparator = ((sortColumn) => {
        return (a, b) => {
            switch (typeof (a[sortColumn])) {
                case "string":
                    return a[sortColumn].localeCompare(b[sortColumn]);
                    break;
                case "date":
                    return Number(a[sortColumn]) == Number(b[sortColumn]) ? 0 : Number(a[sortColumn]) > Number(b[sortColumn]) ? 1 : -1;
                    break;
                case "number":
                    return a[sortColumn] - b[sortColumn];
                    break;
            }

        };
    })
    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;

        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return sortedRows.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, sortColumns, rowSelect, rows]);

    const rowKeyGetter = (row) => {
        return row.id != undefined ? row.Id : row.Id;
    }
    const sortPriorityClassname = "dcs-sort";
    const renderSortStatus = ({ sortDirection, priority }) => {
        return (
            <>
                {sortDirection !== undefined ? (sortDirection === 'ASC' ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />) : <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    alignItems: 'center',
                    fontSize: "10px",
                    marginTop: "10px"
                }}><FontAwesomeIcon icon={faChevronUp} /><FontAwesomeIcon icon={faChevronDown} /></div>}
                {/* <span className={sortPriorityClassname}>{priority}</span> */}
            </>
        );
    }

    //FILTER REGION
    
    
   
   
    //END FILTER REGION
    return (
        <>
            <div>
                {
                    showTitle ? <h5 className="dcs-Title">{title} {tips ? <> <span class="tips">
                        <FontAwesomeIcon icon={faExclamationCircle} id={"tips-questionarysent"} /></span>
                        <UncontrolledTooltip placement="bottom"
                            target={"tips-questionarysent"}>
                            {tips}
                        </UncontrolledTooltip></> : null}
                    </h5> : null

                }
            </div>
            <div>
                {
                    currentTableData.length > 0 ?
                         <FilterContext.Provider value={filters}>
                            <DataGrid dark className={`${filters.enabled ? "filterContainerClassname":undefined} fill-grid dcs-table ${props.className && props.className} `}
                                rows={currentTableData}
                                columns={columns}                                
                                rowKeyGetter={rowKeyGetter}
                                onSelectedRowsChange={setSelectedRows}
                                rowHeight={rowHeight}
                                style={style}
                                //rowHeight={(row) => (row.type === 'DETAIL' ? 300 : rowHeight!=undefined?rowHeight:45)}
                                onRowsChange={onRowsChange}
                                headerRowHeight={filters.enabled ? 90 : headerHeight}
                                selectedRows={selectedRow}
                                defaultColumnOptions={{
                                    sortable: true,
                                    resizable: true
                                }}
                                renderers={{ renderSortStatus }}
                                //headerRowHeight={headerRowHeight}
                                onSortColumnsChange={!filters.enabled?setSortColumns:undefined}
                                sortColumns={sortColumns}
                            />
                        </FilterContext.Provider>
                        : <div className="col-md-12 text-center">No data to display</div>}
            </div>
            {pagination ?
                <Pagination
                    key="dtpagination"
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={rows.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                /> : null}
        </>
    )
}
export default DynamicTable;