import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Col, Row, TabPane, UncontrolledTooltip } from 'reactstrap';
import Card from "../../components/ui/Card/Card";
import SupplierCounter from '../../components/ui/SupplierCounter/SupplierCounter';
import CustomerRatingScore from '../../components/ui/CustomerRatingScore/CustomerRatingScore';
import CustomerRatingNScore from '../../components/ui/CustomerRatingNScore/CustomerRatingNScore';
import Tab from '../../components/ui/Tab/Tab';
import DynamicTable from '../../components/ui/Table/Table';
import SmallRating from '../../components/ui/SmallRating/SmallRating';
import DCSDoughnut from '../../components/ui/Charts/Doughnut/Doughnut';
import LoaderContainer from '../../components/ui/LoaderContainer/LoaderContainer';
import { SeverityColors, SeverityName } from '../../components/ui/Helpers/Colors';
//import { useVulnerabilityContext } from '../../contexts/vulnerabilityContext';
import { Suppliers } from '../../data/suppliers';
import { Link } from 'react-router-dom';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeatMapGrid } from 'react-heatmap-grid';
import QuestionaryCalculator from '../../utils/questionaryCalculator';
import DataTable from '../../components/ui/Table/DataTable';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useAuth } from '../../contexts/authContext';
import { getRatings } from '../../data/administration';
import RatingFormulaCalculator from '../../utils/ratingFormulaCalculator';
import { PostureRatingSupplier, PostureRatingSuppliers } from '../../utils/ratingCalculator';
import { GetQuestionaries } from '../../data/questionary';
const Home = (props) => {
  const [Vulnerabilities, setVulnerabilities] = useState(null);
  const [supplierData, setSuppliers] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [vulerabilitiesLoading, setVulLoading] = useState(false);
  const [questionariesSent, setQuestionariesSent] = useState(null);
  const [questionariesAwaitingApproval, setQuestionariesAwaitingApproval] = useState(null);
  const [avgScore, setAvgScore] = useState(null);
  const [risk, setRisk] = useState(null);
  const [formula, setFormula] = useState(null);
  const [generalRating, setGeneralRating] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();
  useMemo(() => {

    // let newData = [];
    // if (supplierData != null) {
    //   supplierData.map(supplier => {
    //     supplier.Assets.map(asset => {
    //       if (asset.IsOfInterest) {
    //         asset.AssetScans.map(scan => {
    //           if (scan.Risk_Level >1 && scan.Ignore == 0) {
    //             newData.push({
    //               id: scan.Id,
    //               SupplierId: supplier.Id,
    //               severity: scan.Risk_Level,
    //               Supplier: supplier.SupplierName,
    //               Asset: asset.Name,
    //               CVE: scan.CVE,
    //               Risk_Description: scan.Risk_Description,
    //               Description: scan.Description
    //             })
    //           }
    //         })
    //       }
    //     })
    //   })
    // }
    // newData = newData.sort((a, b) => b.severity - a.severity);
    // if (newData.length > 0) {
    //   setVulnerabilities(newData.slice(0, 6))
    // }
    // setVulLoading(false);

  }, [supplierData])
  const Title = "Dashboard";

  useEffect(() => {
    setVulLoading(true);
    GetQuestionaries(auth.user.ClientId).then(rez=>{
      console.log(rez);
      const qsent=rez.data.filter(x=>x.u.Status=="Sent");
      const qawaitingapproval=rez.data.filter(x=>x.u.Status=="Responded");
      setQuestionariesSent(qsent);
      setQuestionariesAwaitingApproval(qawaitingapproval);
    })
    Suppliers(null, auth.user.ClientId).then(res => {
      setSuppliers(res.data);
      // const qawaitingapproval=res.data.map(x => {
      //   if (x.Questionaries.length > 0) {
      //     let supplierName = x.SupplierName;
      //     let re = x.Questionaries.filter(x => x.Status == "Sent");
      //     re.Supplier = x.Supplier;
      //     re = re.map(p => { p.Supplier = supplierName; return p });
      //     //console.log(re);
      //     setQuestionariesSent(re)

      //     re = x.Questionaries.filter(x => x.Status == "Responded");
      //     re.Supplier = x.Supplier;
      //     re = re.map(p => { p.Supplier = supplierName; return p });
      //     // console.log(re);
      //     return re;
      //   }
      // });
      // setQuestionariesAwaitingApproval(qawaitingapproval.flat());
    });
    // getRatings(auth.token, auth.user.ClientId).then(rez => {
    //   if (rez.data != undefined) {
    //     setFormula(rez.data);
    //   }
    // })
  }, [])

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  }

  let t = 0;
  const initialCVESort = [{ columnKey: 'severity', direction: 'DESC' }];
  const CVEColumns = [
    {
      key: 'id',
      name: 'Supplier',
      resizable: false,
      defaultFlex: 2,
      render: ({ data }) => {
        return (<div>
          <h6>
            {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
              <Link to={"portfolio/suppliers/" + data.Asset.SupplierId} className="no-markings">{data.Asset.Suppliers.Name}</Link> : data.Asset.Suppliers.Name}
            {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
          </h6>
          <span>{data.Asset.Name}</span>
        </div>)
      },
      // cellClass: "normalLineHeight"
    },
    {
      key: 'severity',
      name: 'Risk_Level',
      defaultFlex: 1,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      resizable: false,
      render: ({ data }) => {
        //console.log(data);
        return (
          <span className="label" id={`severity-` + data.Id} style={{ backgroundImage: "linear-gradient(to bottom right, " + SeverityColors(data.Risk_Level) + ")", display: "flex", justifyContent: "center", padding: "5px", borderRadius: "10px", width: "100%" }}>
            {data.Risk_Description != "" ?
              <UncontrolledTooltip target={`severity-` + data.Id} >
                {data.Risk_Description}
              </UncontrolledTooltip>
              : null}
            {SeverityName(data.Risk_Level)}
          </span>
        )

      }
    }
  ];

  const RefreshAnswer = () => {

  }
  const QuestioanryColumns = [
    {
      header: 'Supplier',
      name: 'Supplier',
      defaultWidth: 275,
      render: ({ data }) => {
        // console.log(params);
        return (<div><h6>
          {
            auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
              <Link to={"portfolio/suppliers/" + data.u.SupplierId} className="no-markings">{data.uir.Name}</Link> : data.uir.Name
          }
          {/* <Link to={"portfolio/suppliers/" + data.SupplierId} className="no-markings">{data.Supplier}</Link> */}
          {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
        </h6>
        </div>)
      },
      
    },
    {
      header: 'Status',
      name: 'Status',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      resizable: false,
      render: ({ data }) => {
        // console.log(params);
        return ( data.u.Status
       )
      },
    },
    {
      header: 'DueDate',
      name: 'DueDate',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      resizable: false,
      render: ({ data }) => {
        return new Date(data.u.DueDate).toLocaleDateString('en-US');
      }
    },
    {
      header: 'DateSent',
      name: 'DateSent',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      resizable: false,
      render: ({ data }) => {
        return new Date(data.u.DateSent).toLocaleDateString('en-US');
      }
    }
  ];
  const AwaitingApprovalColumns = [
    {
      header: 'Supplier',
      name: 'Supplier',
      defaultWidth: 275,
      render: ({ data }) => {
        // console.log(params);
        return (<div><h6>
          {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
            <Link to={"portfolio/suppliers/" + data.u.SupplierId} className="no-markings">{data.uir.Name}</Link> : data.uir.Name}
          {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
        </h6>
        </div>)
      },
      cellClass: "normalLineHeight"
    },
    {
      header: 'Questionary',
      name: 'Questionary',    
      render: ({ data }) => {
        // console.log(data);
        return (<div><h6>
          {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
            <Link to={"portfolio/suppliers/" + data.u.SupplierId} className="no-markings">{data.u.Questionary.Name}</Link> : data.Supplier}
          {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
        </h6>
        </div>)
      },
      cellClass: "normalLineHeight"
    },
    {
      header: 'Status',
      name: 'Status',
      defaultWidth: 100,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      showColumnMenuTool: false,
      resizable: false,
      render: ({ data }) => {
        return data.u.Status
      }
    },
    {
      header: 'DueDate',
      name: 'DueDate',
      defaultWidth: 100,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      resizable: false,
      render: ({ data }) => {
        //console.log(data);
        return new Date(data.u.DueDate).toLocaleDateString('en-US');
      }
    },
    {
      header: 'DateSent',
      name: 'DateSent',
      defaultWidth: 100,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      resizable: false,
      render: ({ data }) => {
        return new Date(data.u.DateSent).toLocaleDateString('en-US');
      }
    }   
  ];
  const columns = [
    {
      header: 'Supplier Name',
      name: 'supplierName',
      sortable: true,
      defaultWidth: 300,
      render: ({ data }) => {
        //console.log(params)

        return <>{auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ? <Link to={"portfolio/suppliers/" + data.id} className="no-markings">{data.supplierName}</Link> : data.supplierName}</>
      }
    },
    {
      header: 'Rating',
      name: 'rating',
      sortable: true,
      render: ({ data }) => {
        //console.log(params)
        return <strong>
          <SmallRating rating={data.rating} />
        </strong>
      }
    },
    {
      header: 'Date Created',
      name: 'dateCreated',
      sortable: true,
      type: 'date',
      render: ({ data }) => {
        return data.dateCreated.split("T")[0];
      }
      // valueGetter: ({ value }) => value && new Date(value),
    }
  ];
  useEffect(() => {
    if(supplierData!=null)
    {
      setGeneralRating(supplierData.Rating.Rating);
      setAvgScore(supplierData.Rating.Score);
      setVulnerabilities(supplierData.Vulnerabilities);
      setVulLoading(false);
    }
    // if (supplierData != null) {
    //   let rmapping = supplierData.map(x => {
    //     const r = PostureRatingSupplier(x, formula);        
    //     if (r.PostureRating < 100 || r.PostureRating==undefined) {
    //       return {
    //         id: x.Id,
    //         supplierName: x.SupplierName,
    //         rating: r.Rating,
    //         dateCreated: x.DateCreated
    //       }
    //     }

    //   });
    //  // console.log(rmapping);
    //   setRisk(rmapping.filter(x => x != undefined));
    // }


    //   if (QuestionaryRating != -1) {
    //       //console.log(Rating);
    //       setRisk(Rating)
    //   } else
    //     setRisk(0)
    // }
    // let risk = supplierData.map(x => {
    //   let issues = 0;
    //   x.Assets.map(as => {
    //     as.AssetScans.map(assetScan => {
    //       if (assetScan.Risk_Level >= 2 && assetScan.Ignore == 0) {
    //         issues++;
    //       }
    //     })

    //   })
    //   if (issues > 0) {
    //     return {
    //       id: x.Id,
    //       supplierName: x.SupplierName,
    //       rating: 0,
    //       dateCreated: x.DateCreated
    //     }
    //   };
    // }).flat();
    // risk = risk.filter(function (element) {
    //   return element !== undefined;
    // });
    // if (risk.length > 0) {

    //   setRisk(risk);
    // }
  }, [supplierData])

  //console.log(questionariesSent);
  const Tabs = [
    { name: "Top highest risk", id: "!", title: "Top 10 highest risk suppliers", columns: columns, rows: risk },
    { name: "Awaiting approval", id: "2", title: "Completed questionaries awaiting approvail", columns: AwaitingApprovalColumns, rows: questionariesAwaitingApproval },
    { name: "Questionaries", id: "3", title: "Sent questionaries", columns: QuestioanryColumns, rows: questionariesSent }
  ];
  const Scores = [
    { score: "N/A", rating: "N/A", color: "#979797" },
    { score: "A", rating: "GREAT", color: "#1BC000" },
    { score: "B", rating: "GOOD", color: "#02830F" },
    { score: "C", rating: "BAD", color: "#F97316" },
    { score: "D", rating: "CRITICAL", color: "#EF4444" },

  ];
  // useEffect(() => {
  //   if (supplierData != null && formula != null) {
  //    // CalculateRating();
  //   }

  // }, [supplierData, formula])

  // const CalculateRating = () => {

  //   let Rating = [];
  //   let suppliersReviewed = 0;
  //   // supplierData.map(supplier => {
  //   //   let positionRating = 0;
  //   //   let assetBusinessImpact = 0;

  //   //   let a = supplier.Assets.map(x => {
  //   //     if (x.IsOfInterest) {
  //   //       assetBusinessImpact = assetBusinessImpact + x.BusinessImpact
  //   //       if (x.AssetScans.length > 0) {
  //   //         return x.AssetScans.reduce((acc, val) => acc + val.Risk_Level, 0);
  //   //       } else { return 0 }
  //   //     }
  //   //   });
  //   //   a = a.filter(l => l !== undefined);
  //   //   //Posture Calculation
  //   //   const posture =a.length>0? a.reduce((acc, val) => acc + val, 0) / a.length:0;
  //   //   const scale = posture / 10;
  //   //   const PostureRating = posture / 10
  //   //   console.log(PostureRating);

  //   //   //Questionary Calculation          
  //   //   const qr = supplier.Questionaries.length > 0 && supplier.Questionaries.filter(x => x.Status == "Approved").length > 0 ? QuestionaryCalculator(supplier.Questionaries[0]) : 0;
  //   //   if (supplier.Questionaries.length > 0 && supplier.Questionaries.filter(x => x.Status == "Approved").length > 0) {
  //   //     QuestionaryRating = qr;
  //   //   }
  //   //   const assetbimpact = assetBusinessImpact / supplier.Assets.filter(x => x.IsOfInterest).length;
  //   //   //Rating Calculation
  //   //   const values = {
  //   //     PostureRating: PostureRating,
  //   //     QuestionaryRating: QuestionaryRating / 100,
  //   //     BusinessImpact: supplier.positionalRating,
  //   //     AssetBusinessImpact: assetbimpact
  //   //   }
  //   //   const avgRating = RatingFormulaCalculator(formula.Formula, values);
  //   //   setAvgScore(avgRating.score);
  //   //   if (supplier.Questionaries.length > 0 && supplier.Questionaries.filter(x => x.Status == "Approved").length > 0) {
  //   //     suppliersReviewed += supplier.Questionaries.length > 0 ? 1 : 0;
  //   //     Rating.push(avgRating.rating);
  //   //   }

  //   //   // Rating = Math.round(1 + 3 * Math.exp(-RatingScale * 10));
  //   // });
  //   // console.log(Rating);
  //   // console.log(suppliersReviewed);
  //   const r = PostureRatingSuppliers(supplierData, formula);
  //   if (r != undefined) {
  //     //console.log(r);
  //     if (r.QuestionaryRating > 0) {
  //       setAvgScore(r.avgRating);
  //       setGeneralRating(r.Rating);
  //     } else {
  //       setAvgScore(0);
  //       setGeneralRating(0);
  //     }
  //   } else {
  //     setGeneralRating(0);
  //     setAvgScore(0);
  //   }
  //   //return Scores[5];
  // }
  const onSelectionChange = useCallback((selected) => {
    // console.log(selected.data);
    if (auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0)
      navigate("/portfolio/suppliers/" + selected.data.Asset.SupplierId);

  }, [])
  return (
    <>
      {/* <AuthenticatedTemplate> */}
      {supplierData != null ?
        <>
          <Row>
            <Col md="12" xs="12">
              <h3 className="dcs-Title bold">{Title}</h3>
            </Col>
          </Row>
          <Row>
            <Col md="5" xs="12" >
              <Col>
                <Card>
                  <Row className="customerGeneralInfo">
                    <SupplierCounter number={supplierData.length > 0 ? supplierData.NewSuppliers : 0} percentage={100} />
                    <Col md="4" xs="12" className='supplierCounter'>
                      {generalRating != null ?
                        <CustomerRatingScore gRating={generalRating!=null ? Scores[generalRating].score : Scores[0].score} /> : null
                      }
                    </Col>
                    {avgScore != null ?
                      <CustomerRatingNScore rating={Math.round(avgScore)} /> : null}
                  </Row>
                </Card>
              </Col>
              <Row>
                <Col md="6" xs="12">
                  <Card>
                    <h5 className='dcs-Title'>Supplier Score Distribution</h5>
                    <Row>
                      <Col md="12">
                        {supplierData != null ?
                          <DCSDoughnut supplierData={supplierData.Rating.SupplierRatings} /> : <LoaderContainer />}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md="6" xs="12" style={{ paddingLeft: 0 }}>
                  <Card>
                    <h5 className='dcs-Title'>Latest Vulnerabilities</h5>
                    <Row>
                      <Col md="12">
                        {!vulerabilitiesLoading ?
                          <DataTable columns={CVEColumns} enableSelection={true} showCellBorders="none" rowHeight={80} showZebraRows={false} showHeader={false} defaultLimit={10} data={Vulnerabilities != null ? Vulnerabilities : []} id="assets" onSelectionChange={onSelectionChange} />
                          // <DynamicTable showTitle={false} className={"rdg-header-row-none"} rowHeight={65} defaultsortColumns={initialCVESort} PageSize={6} pagination={true} headerHeight={0} rows={Vulnerabilities} columns={CVEColumns} />
                          : <LoaderContainer />}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="5" xs="12">
              <Card>
                <Tab tabs={Tabs}>
                  {Tabs.map(x => {
                    return (
                      <TabPane tabId={x.id} key={x.id}>
                        <DataTable rowHeight={50} columns={x.columns} showCellBorders={false} title={x.title} showZebraRows={false} showHeader={true} data={x.rows != null ? x.rows : []} virtualizeColumns={false} pagination={x.rows != null ? x.rows.length > 10 ? true : false : false} defaultLimit={10} />
                        {/* <DynamicTable showTitle={true} rowHeight={50} title={x.title} rows={x.rows} PageSize={10} columns={x.columns} /> */}

                      </TabPane>
                    )
                  })}
                </Tab>
              </Card>
            </Col>
            <Col md="2" xs="12">
              <Card padding="0">
                <img src="/images/image1.png" />
                <h5 className='text-center'>Request pentesting service</h5>
              </Card>
            </Col>
          </Row>
        </> : <LoaderContainer />}
      {/* </AuthenticatedTemplate> */}
      {/* <UnauthenticatedTemplate>
        
          <center>Please sign-in to see your profile information.</center>
        
      </UnauthenticatedTemplate> */}
    </>
  )
}
export default Home;